import React from 'react'
//import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import Layout from '../../components/Layout'
import {getDateAndMonth} from '../../utils/date-utils'

import banner_3 from '../../images/banners/banner-3.jpg'

const IndexNewsPage = ({
  location,
  data: {
    site: { siteMetadata: { title } },
    allPosts: { edges: posts }    
  },
}) => (
    <Layout location={location}>
      <Helmet title={`Actualités | ${title}`} />

      <div id="top-banner" className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-xs-12">
            <img src={banner_3} alt="Actualités" className="full-width-image" />
          </div>
        </div>
      </div>      

      <div id="breadcrumbs-container" className="container-fluid horizontal-section-container hidden-xs clearfix">
        <div className="row">
          <div className="col-xs-12">
            <ol className="breadcrumb">
              <li><Link to="/">Accueil</Link></li>
              {/* eslint-disable-next-line */}
              <li className="active"><a href="#">Actualités</a></li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">

          <div className="col-sm-12">
            <div className="section-container main-page-content clearfix">

              <div className="section-content">

                <h1 className="page_title">Actualités</h1>

                <div className="media-list news-list clearfix">
                  {
                    posts
                      .map(({ node: post }) => (
                        <div className="media clearfix" key={post.id}>
                          <div className="pull-left img-mask-effect fade">
                            <Img sizes={post.frontmatter.cover_image_home.childImageSharp.sizes} imgStyle={{position: "relative"}} />
                          </div>
                          <div className="media-body">
                            <h3 className="media-heading">
                              <Link to={post.fields.slug}>
                                {post.frontmatter.title}
                              </Link>
                            </h3>
                            <div><span className="inline-date">{getDateAndMonth(post.frontmatter.date)}</span></div>
                            <p>{post.excerpt}</p>
                            <p><Link className="btn btn-outline-inverse btn-sm" to={post.fields.slug}>
                              lire la suite
                            </Link></p>
                          </div>
                        </div>
                      ))
                  }
                </div>

                {/* Pagination */}

              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )

export default IndexNewsPage

export const indexNewsPageQuery = graphql`
  query IndexNewsQuery {
    site {
      siteMetadata {
        title
      }
    },
    allPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { templateKey: { eq: "news-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            cover_image_home {
              childImageSharp {
                sizes(maxWidth: 1068) {
                  srcSet
                }
              }
            }
            date
          }
        }
      }
    }
  }
`
