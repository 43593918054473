const monthNames = ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sept", "Oct", "Nov", "Déc"]

export function getDate(date) {
    return ('0' + new Date(date).getDate()).slice(-2)
}

export function getMonth(date) {
    return monthNames[new Date(date).getMonth()]
}

export function getDateAndMonth(date) {
    return (new Date(date).getDate()) + ' ' + monthNames[new Date(date).getMonth()]
}